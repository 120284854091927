import { ready } from './lib/utils';
import { hero } from './modules/hero.js';

const home = {

	init() {
		hero.init();
	}

};

ready(function () {
	home.init();
});