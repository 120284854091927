import { mql, toggleVideoPlay } from '../lib/utils';

/**
 * Hero component.
 * @module components/hero
*/
const hero = {

	elem: {
		$hero: null
	},

	init() {
		this.elem.$hero = ($('.hero').length > 0) ? $('.hero') : null;

		if (this.elem.$hero === null) {
			return;
		}

		this.bindUIActions();
		this.onLoad();
		this.onResize(mql.medium);
	},

	bindUIActions() {
		mql.medium.addListener(this.onResize.bind(this));

		this.elem.$hero.on('click', '.pause-button', toggleVideoPlay);
	},

	onLoad() {
		let initPlay = true;

		const hero = document.getElementsByClassName('hero')[0];
		const video = hero.getElementsByTagName('video')[0];

		video.addEventListener('canplaythrough', () => {
			if (initPlay) {
				initPlay = false;

				this.onResize(mql.medium);
			}
		});
	},

	onResize(mq) {
		const $svg = this.elem.$hero.find('.hero__mask');
		const $text = $svg.find('text');

		$text.attr('dy', () => (mq.matches) ? '25%' : '0%');
	}

};

export { hero };